import { TFabricationMethod } from '@kerfed/types';

export const PROCESS_OPTIONS = [
  {
    key: 'FABRICATION_METHOD_FLAT',
    value: 'flat',
    text: 'Waterjet / Laser',
    description:
      "CNC lasers or waterjets can produce even large (5'x10') flat parts very quickly and accurately (typically 0.005\" or better).",
  },
  {
    key: 'FABRICATION_METHOD_BENT',
    value: 'bent',
    text: 'CNC Bent',
    description:
      'CNC press brakes can take a laser or waterjet blank and form it with cylindrical bends into complex boxes, brackets, and other parts. ',
  },
  {
    key: 'FABRICATION_METHOD_ROLL',
    value: 'roll',
    text: 'CNC Roll',
    description:
      'CNC slip rolling can take a laser or waterjet blank of material up to 0.25" thick and roll it into a cylinder.',
  },
  {
    key: 'FABRICATION_METHOD_TURN',
    value: 'turn',
    text: 'CNC Lathe',
    description:
      'CNC lathes such as the Haas ST15 or Mazack QT 200 can produce radially symmetric parts very quickly.',
  },
  {
    key: 'FABRICATION_METHOD_MILL',
    value: 'mill',
    text: 'CNC Mill',
    description:
      'Conventional CNC milling can produce complex geometry as a series of extruded cuts using machines such as the Haas VF2SS or the Fanuc Robodrill.',
  },

  {
    key: 'FABRICATION_METHOD_COTS',
    value: 'cots',
    text: 'COTS',
    description:
      'The part is commercially available and has been identified from a geometric hash.',
  },
  {
    key: 'FABRICATION_METHOD_ADD',
    value: 'add',
    text: '3D Print',
    description:
      'Additive techniques such as FDM and SLS can produce intricate geometries inexpensively.',
  },
  {
    key: 'FABRICATION_METHOD_MANUAL',
    value: 'manual',
    text: 'Manual Quote',
    description: 'Have the part quoted manually.',
  },
];

export const CATEGORY_ORDER = ['material', 'thickness', 'finish', 'color'];

export const MAP_METHOD: Record<TFabricationMethod, string> = {
  FABRICATION_METHOD_ADD: 'add',
  FABRICATION_METHOD_BENT: 'bent',
  FABRICATION_METHOD_COTS: 'cots',
  FABRICATION_METHOD_FLAT: 'flat',
  FABRICATION_METHOD_MANUAL: 'manual',
  FABRICATION_METHOD_MILL: 'mill',
  FABRICATION_METHOD_ROLL: 'roll',
  FABRICATION_METHOD_TURN: 'turn',
  FABRICATION_METHOD_UNSPECIFIED: 'unspedified',
};

/**
 * add: "FABRICATION_METHOD_ADD"
 */
export const MAP_METHOD_ENUM: Record<
  keyof typeof MAP_METHOD,
  TFabricationMethod
> = {
  FABRICATION_METHOD_ADD: 'FABRICATION_METHOD_ADD',
  FABRICATION_METHOD_BENT: 'FABRICATION_METHOD_BENT',
  FABRICATION_METHOD_COTS: 'FABRICATION_METHOD_COTS',
  FABRICATION_METHOD_FLAT: 'FABRICATION_METHOD_FLAT',
  FABRICATION_METHOD_MANUAL: 'FABRICATION_METHOD_MANUAL',
  FABRICATION_METHOD_MILL: 'FABRICATION_METHOD_MILL',
  FABRICATION_METHOD_ROLL: 'FABRICATION_METHOD_ROLL',
  FABRICATION_METHOD_TURN: 'FABRICATION_METHOD_TURN',
  FABRICATION_METHOD_UNSPECIFIED: 'FABRICATION_METHOD_UNSPECIFIED',
};

export const INITIAL_BACKOFF_TIME_MS = 2000;

export const SUPORTED_FILE_TYPES = [
  'STEP',
  '3DXML',
  'DXF',
  'STL',
  '3MF',
  'GLTF',
  'PLY',
  'OBJ',
  'AI',
  'STP',
  'GLB',
  'OFF',
  'SVG',
  'PDF',
];

export const FILE_REQUIRED_UNIT = ['OBJ', 'PLY', 'OFF', 'STL', 'DXF', 'SVG'];

export const UNITS = ['Automatic', 'Inches', 'Meters', 'Millimeters'] as const;

export const MAP_UNIT: Record<(typeof UNITS)[number], string | undefined> = {
  Automatic: undefined,
  Inches: 'inches',
  Meters: 'meters',
  Millimeters: 'millimeters',
};
