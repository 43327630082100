import React from 'react';
import { NavLink } from 'react-router-dom';

import UserButton from './UserButton';

import '../styles/navbar.scss';

export default () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img className="logo-image" src="/static/images/circle.svg" />
          <strong>KERFED</strong>
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex justify-content-end w-100">
            <ul className="navbar-nav ml-auto align-right">
              <li className="nav-item">
                <NavLink className="nav-text" to="/help">
                  Tutorial{' '}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="instant-button" to="/new">
                  Instant Quote
                </NavLink>
              </li>
              <li className="nav-item">
                <UserButton />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
