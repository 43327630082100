import React from 'react';

import Explainer from '../components/Explainer';
import { FormattedId, Message } from '../components';

import { useFirebaseAuth } from '../hooks/useFirebaseAuth';

import 'bootstrap/js/dist/dropdown';

export default () => {
  const user = useFirebaseAuth();
  const userId = user?.uid;

  if (!user) {
    return (
      <div className="loader ">
        <div className="spinner-border " role="status" />
      </div>
    );
  }

  return (
    <div className="container-md p-3 bg-white">
      <h1>My Account</h1>
      <hr />

      {user.isAnonymous || !userId ? (
        Message('Log in to see account information.', 'Not Logged In')
      ) : (
        <div>
          <h2>User Info</h2>
          <div>
            <img
              className="img-fluid w-25"
              src={user?.photoURL || '/static/images/avatar.svg'}
            />
            <h5> {user?.displayName}</h5>
            <Explainer
              explanation={'UID is your unique user identification number.'}
            >
              UID: <FormattedId id={userId} />
            </Explainer>
            {user?.email ? (
              <div>
                Email: <i>{user.email}</i>
              </div>
            ) : null}
          </div>
        </div>
      )}

      <div className="segmentRectangle" />
    </div>
  );
};
