import React from 'react';

interface CircularLoadingProps {
  label?: React.ReactNode;
}

const CircularLoading = ({ label }: CircularLoadingProps) => {
  return (
    <div data-iframe-height={true} className="align-center loader">
      <div className="spinner-border" role="status" />
      {!!label && <h3 className="p-3">{label}</h3>}
    </div>
  );
};

export default CircularLoading;
