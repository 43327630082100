import React from 'react';
import {
  PartConfiguration,
  FlatFabrication,
  MillFabrication,
  RollFabrication,
  AddFabrication,
  TurnFabrication,
  BentFabrication,
  CotsFabrication,
  PartFabrication,
  Shop,
} from '@kerfed/client';
import { TFabricationMethod } from '@kerfed/types';

import EditableFlatPart from './EditableFlatPart';
import EditableMillPart from './EditableMillPart';
import EditableRollPart from './EditableRollPart';
import EditableAddPart from './EditableAddPart';
import EditableTurnPart from './EditableTurnPart';
import EditableBentPart from './EditableBentPart';
import EditableCotsPart from './EditableCotsPart';

import { MAP_METHOD } from 'app/src/constants';

interface EditableMethodPartProps {
  methodId: TFabricationMethod;
  data: PartFabrication;
  shop?: Shop;
  notes?: string;
  quantity?: number;
  config?: PartConfiguration | null;
  onChangePartOrderData: (partId: string, newConfig: PartConfiguration) => void;
}

const EditableMethodPart = (props: EditableMethodPartProps) => {
  const { methodId, data, ...rest } = props;
  const methodField = MAP_METHOD[methodId] as keyof PartFabrication;
  const methodData = data?.[methodField] as PartFabrication[typeof methodField];

  if (!data?.part_id) return null;

  switch (methodId) {
    case 'FABRICATION_METHOD_FLAT':
      return (
        <EditableFlatPart
          {...rest}
          partId={data?.part_id || ''}
          data={methodData as FlatFabrication}
        />
      );
    case 'FABRICATION_METHOD_MILL':
      return (
        <EditableMillPart
          {...rest}
          partId={data.part_id}
          data={methodData as MillFabrication}
        />
      );
    case 'FABRICATION_METHOD_ROLL':
      return (
        <EditableRollPart
          {...rest}
          partId={data.part_id}
          data={methodData as RollFabrication}
        />
      );
    case 'FABRICATION_METHOD_ADD':
      return (
        <EditableAddPart
          {...rest}
          partId={data.part_id}
          data={methodData as AddFabrication}
        />
      );
    case 'FABRICATION_METHOD_TURN':
      return (
        <EditableTurnPart
          {...rest}
          partId={data.part_id}
          data={methodData as TurnFabrication}
        />
      );
    case 'FABRICATION_METHOD_BENT':
      return (
        <EditableBentPart
          {...rest}
          partId={data.part_id}
          data={methodData as BentFabrication}
        />
      );
    case 'FABRICATION_METHOD_COTS':
      return (
        <EditableCotsPart
          {...rest}
          partId={data.part_id}
          data={methodData as CotsFabrication}
        />
      );
    default:
      return <div>Unable to automatically quote part.</div>;
  }
};

export default EditableMethodPart;
