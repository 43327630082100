import { useEffect, useMemo, useRef, useState } from 'react';
import {
  convertTimestampToDate,
  getNewExponentialBackoffTime,
} from '@kerfed/common/utils';
import { PartConfiguration, PostalAddress, Shop } from '@kerfed/client';
import { TOrder } from '@kerfed/types';

import {
  useAPIGetOrder,
  useAPIOrderLineEdit,
  useAPIOrderFileAdd,
  useAPIOrderShipping,
  useAPIOrderStripe,
} from 'app/src/api/order';
import { useAPIGetShop } from 'app/src/api/shop';

import { TPathWithUnit } from 'app/src/components/FileUploader';
import {
  INITIAL_BACKOFF_TIME_MS,
  MAP_METHOD,
  MAP_UNIT,
} from 'app/src/constants';

const MAX_RETRY_FETCH_ORDER = 4;

type TUseQuoteEditControllerParams = {
  quoteId?: string;
};

export const useQuoteEditController = ({
  quoteId = '',
}: TUseQuoteEditControllerParams) => {
  const [failedGetPartList, setFailedGetPartList] = useState(false);
  const backupOrderData = useRef<TOrder>();
  const backoffTime = useRef(INITIAL_BACKOFF_TIME_MS);
  const dateModified = useRef<Date>();
  const retryFetchOrderCount = useRef(0);
  const initialModelCount = useRef(0);
  const [order, setOrder] = useState<TOrder>();
  const [shop, setShop] = useState<Shop>();
  const [stripeClientSecret, setStripeClientSecret] = useState('');
  const [loadingScreen, setLoadingScreen] = useState<{
    active: boolean;
    message: string;
  }>();
  const [errorPlaceOrder, setErrorPlaceOrder] = useState('');

  // Disable the order button total is invalid
  const disabledPlaceOrder = useMemo(() => {
    // the backend should tell us if we have a valid total.
    // remove any once the type has updated on @kerfed/client
    return !(order?.total as any)?.is_valid;
  }, [order]);

  const { onGetShop } = useAPIGetShop({
    onCompleted: (res) => {
      setShop(res);
    },
  });

  const { onOrderStripe } = useAPIOrderStripe({
    onCompleted: (res) => {
      setStripeClientSecret(res.client_secret);
    },
    onError: (err) => {
      setStripeClientSecret('');
      setErrorPlaceOrder(err?.message || 'Unexpected error.');
      alert('Order placement was unsuccessful. Please try again.');
    },
  });

  const { onOrderShipping, error: errorOrderShipping } = useAPIOrderShipping({
    onCompleted: () => {
      handleRefreshOrderData();
    },
    onError: () => {
      setLoadingScreen(undefined);

      /**
       * Revert change with backup data if failed update
       */
      setOrder(backupOrderData.current);
      alert('There was an issue saving your address. Please try again.');
    },
  });

  const {
    onOrderLineEdit,
    loading: lodingLineEdit,
    error: errorOrderLineEdit,
  } = useAPIOrderLineEdit({
    onCompleted: () => {
      handleRefreshOrderData();
    },
    onError: () => {
      /**
       * Revert change with backup data if failed update
       */
      setOrder(backupOrderData.current);

      /**
       * Stop all loading price when encounter an error
       */
      handleStopAllLoadingPrice();
      alert(
        'Saving your part configuration was unsuccessful. Please try again.',
      );
    },
  });

  const {
    loading: loadingGetOrder,
    error,
    onGetOrder,
  } = useAPIGetOrder({
    onCompleted: (res) => {
      dateModified.current = convertTimestampToDate(
        res?.date_modified?.seconds,
      );
      const newOrderData = {
        ...res,
        lines: res?.lines?.map((line) => ({
          ...line,
          loading_price: false,
        })),
      };
      setOrder(newOrderData);

      /**
       * Store order data to different variable
       * for backup purpose
       */
      backupOrderData.current = newOrderData;

      /**
       * Refetch data if still processing
       */
      if ((res?.state as any)?.is_processing) {
        if (res?.parts?.length) {
          setLoadingScreen(undefined);
        }

        handlePoolInitialOrderData();
      } else {
        backoffTime.current = INITIAL_BACKOFF_TIME_MS;
        setLoadingScreen(undefined);
      }
    },
    onError: (err) => {
      if (err?.name !== 404 && err?.name !== 401) {
        /**
         * Retry the request if getting error
         * and not reach maximum retry count
         *
         * Otherwise stop all loading price if exists
         */
        if (retryFetchOrderCount.current <= MAX_RETRY_FETCH_ORDER) {
          handlePoolInitialOrderData();

          /**
           * Increase the retry count after making request
           */
          retryFetchOrderCount.current = retryFetchOrderCount.current + 1;
        } else {
          backoffTime.current = INITIAL_BACKOFF_TIME_MS;
          retryFetchOrderCount.current = 0;
          handleStopAllLoadingPrice();
          setLoadingScreen(undefined);
          setFailedGetPartList(true);
        }
      }
    },
  });

  const { onOrderFileAdd, loading: loadingAddFile } = useAPIOrderFileAdd({});

  const handleRefreshOrderData = () => {
    if (quoteId) {
      /**
       * Refresh the order data after successfully editing
       * But wait a bit before making the request
       */
      setTimeout(() => {
        onGetOrder({
          variables: {
            options: {
              pathParams: { orderId: quoteId },
              dateSince: dateModified.current,
            },
          },
        });
      }, 1500);
    }
  };

  const handleStopAllLoadingPrice = () => {
    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        lines: prev?.lines?.map((line) => ({
          ...line,
          loading_price: false,
        })),
      };
    });
  };

  const handleOrderLineEdit = (
    lineId: string,
    newConfig: PartConfiguration,
  ) => {
    if (!quoteId) return;

    onOrderLineEdit({
      variables: {
        options: {
          pathParams: {
            lineId,
            orderId: quoteId,
          },
        },
        params: {
          ...newConfig,
          expedite: newConfig.expedite || 'standard',
        },
      },
    });
  };

  /**
   * Initially the API will not return with the parts data
   * we should re-pull the data until we get the parts data.
   *
   * Re-pulling will be done by using the exponential backoff
   *
   */
  const handlePoolInitialOrderData = () => {
    const newBackoffTime = getNewExponentialBackoffTime(backoffTime.current);
    backoffTime.current = newBackoffTime;

    setTimeout(() => {
      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
      });
    }, newBackoffTime);
  };

  const handleChangeMethod = (partId: string, newSelectedMethodId: string) => {
    const part = order?.parts?.find((part) => part?.part_id === partId);
    const line = order?.lines?.find((line) => line?.config?.part_id === partId);
    const methodField = MAP_METHOD[newSelectedMethodId];

    if (!part || !line) return;

    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        lines: prev.lines?.map((line) => {
          if (line.config?.part_id === partId) {
            return {
              ...line,
              loading_price: true,
              config: {
                ...part[methodField].defaults,
                notes: line.config.notes,
                quantity: line.config.quantity,
              },
            };
          }

          return line;
        }),
      };
    });

    /**
     * Request to API line edit everytime
     * the user change the method
     */
    if (methodField && part?.[methodField]?.defaults && line?.line_id) {
      handleOrderLineEdit(line.line_id, {
        ...part[methodField].defaults,
        quantity: line?.config?.quantity,
        notes: line?.config?.notes,
      });
    }
  };

  const handleChangePartOrderData = (
    lineId: string,
    _: string,
    newConfig: PartConfiguration,
  ) => {
    setOrder((prev) => {
      if (!prev) return;

      return {
        ...prev,
        lines: prev?.lines?.map((line) => {
          if (line.line_id === lineId) {
            return {
              ...line,
              loading_price: true,
              config: newConfig,
            };
          }
          return line;
        }),
      };
    });
    /**
     * Request to API line edit everytime
     * the user made a change on the part configuration
     * expect change on the notes
     */
    handleOrderLineEdit(lineId, newConfig);
  };

  const handleAddOrderFile = async (files: TPathWithUnit[]) => {
    if (quoteId && files.length) {
      /**
       * Set the order processing status as true
       * to show loading indicator on the UI
       */
      setOrder((prev) => {
        if (!prev) return undefined;
        const newState: any = { ...prev.state, is_processing: true };
        return {
          ...prev,
          state: newState,
        };
      });

      initialModelCount.current = order?.models?.length || 0;
      let tmpProgressFileCount = 0;
      for (const file of files) {
        await onOrderFileAdd({
          variables: {
            params: {
              file_id: file.path,
              units: MAP_UNIT?.[file.unit] || undefined,
            },
            options: {
              pathParams: { orderId: quoteId },
            },
          },
          onCompleted: () => {
            tmpProgressFileCount += 1;
          },
        });
      }

      if (tmpProgressFileCount > 0) {
        handlePoolInitialOrderData();
      }
    }
  };

  const handleChangeShipping = (newShipping: Partial<PostalAddress>) => {
    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        shipping: {
          ...prev.shipping,
          ...newShipping,
        },
      };
    });

    setLoadingScreen({
      active: true,
      message: 'Updating Address',
    });
    onOrderShipping({
      variables: {
        options: {
          pathParams: { orderId: quoteId },
        },
        params: {
          ...order?.shipping,
          ...newShipping,
        },
      },
    });
  };

  const handleOnPlaceOrder = () => {
    if (order?.order_id) {
      setLoadingScreen({
        active: true,
        message: 'Finalizing Order',
      });
      setStripeClientSecret('');
      setErrorPlaceOrder('');
      onOrderStripe({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
          },
          params: {
            order_id: order.order_id,
          },
        },
        onFinnaly: () => {
          setLoadingScreen(undefined);
        },
      });
    }
  };

  const handleCancelPlaceOrder = () => {
    setLoadingScreen({
      active: true,
      message: 'Refreshing Order',
    });
    onGetOrder({
      variables: {
        options: {
          pathParams: { orderId: quoteId },
          dateSince: dateModified.current,
        },
      },
    });
    setStripeClientSecret('');
  };

  useEffect(() => {
    if (quoteId && !order) {
      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
      });
    }
  }, [quoteId, order]);

  useEffect(() => {
    if (order?.shop_id && !shop) {
      onGetShop({
        variables: {
          options: { pathParams: { shopId: order.shop_id } },
        },
      });
    }
  }, [order?.shop_id, shop]);

  useEffect(() => {
    return () => {
      if (backoffTime?.current) {
        backoffTime.current = INITIAL_BACKOFF_TIME_MS;
      }

      if (retryFetchOrderCount?.current) {
        retryFetchOrderCount.current = 0;
      }
    };
  }, []);

  return {
    loadingGetOrder,
    loadingPartialUpdate: lodingLineEdit || loadingAddFile,
    errorPartialUpdate: errorOrderShipping || errorOrderLineEdit,
    order,
    shop,
    stripeClientSecret,
    errorGetOrder: error,
    loadingScreen,
    errorPlaceOrder,
    disabledPlaceOrder,
    failedGetPartList,
    onChangeMethod: handleChangeMethod,
    onChangePartOrderData: handleChangePartOrderData,
    onAddOrderFile: handleAddOrderFile,
    onChangeShipping: handleChangeShipping,
    onPlaceOrder: handleOnPlaceOrder,
    onCancelPlaceOrder: handleCancelPlaceOrder,
  };
};
