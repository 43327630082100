import React, { useMemo } from 'react';
import { PartConfiguration, Shop } from '@kerfed/client';
import { TFabricationMethod, TOrder, TOrderLine } from '@kerfed/types';

import EditableMethodPart from '../EditableMethodPart';
import Explainer from 'app/src/components/Explainer';
import Detection from '../Detection';
import GeometryImage from '../GeometryImage';
import ItemRowPrice from '../ItemRowPrice';
import MethodSelector from '../MethodSelector';
import { FormattedId } from 'app/src/components/formatting';

interface EditablePartListProps {
  order: TOrder;
  shop?: Shop;
  onChangePartOrderData: (partId: string, newConfig: PartConfiguration) => void;
  onChangeMethod: (partId: string, newSelectedMethodId: string) => void;
}

const EditablePartList = ({
  order,
  shop,
  onChangePartOrderData,
  onChangeMethod,
}: EditablePartListProps) => {
  const linePart = useMemo(() => {
    if (!order?.parts?.length || !order?.lines?.length) return {};

    let objConfig: Record<string, TOrderLine | undefined> = {};
    for (const part of order.parts) {
      if (!part?.part_id) continue;

      const lineConfig = order.lines?.find(
        (line) => line?.config?.part_id === part?.part_id,
      );
      objConfig = {
        ...objConfig,
        [part.part_id]: lineConfig,
      };
    }

    return objConfig;
  }, [order]);

  const handleChangeMethod =
    (partId?: string | null) => (newSelectedMethod: string) => {
      if (!partId) return;
      onChangeMethod(partId, newSelectedMethod);
    };

  return (
    <tbody>
      {order?.parts?.map((part) => {
        const activeLinePart = linePart?.[part?.part_id || ''];

        return (
          <tr key={part.part_id}>
            <td>
              <div>
                <div className="row">
                  <div className="col-md-9">
                    <h2>{part.geom_name}</h2>
                    <div className="id-text">
                      <Explainer
                        explanation={
                          'GPN is the Geometric Part Number, which may be referenced in drawings and invoices.'
                        }
                      >
                        GPN:&nbsp;
                        <FormattedId id={part?.geom_id || ''} />
                      </Explainer>
                    </div>
                    <Detection geomId={part?.geom_id} models={order?.models} />
                  </div>

                  <div className="col-md-3 align-right">
                    <GeometryImage
                      geomId={part.geom_id}
                      models={order.models}
                    />
                  </div>
                </div>

                <MethodSelector
                  part={part}
                  shop={shop}
                  selectedMethod={activeLinePart?.config?.method_id || ''}
                  onChange={handleChangeMethod(part.part_id)}
                />

                <EditableMethodPart
                  methodId={
                    activeLinePart?.config?.method_id as TFabricationMethod
                  }
                  data={part}
                  shop={shop}
                  config={activeLinePart?.config}
                  onChangePartOrderData={onChangePartOrderData}
                />
              </div>
            </td>

            <ItemRowPrice
              loading={!!activeLinePart?.loading_price}
              enabled={!!activeLinePart?.config?.quantity}
              linePrice={activeLinePart?.price}
            />
          </tr>
        );
      })}
    </tbody>
  );
};

export default EditablePartList;
