import React from 'react';
import { createPortal } from 'react-dom';

import CircularLoading from '../CircularLoading';

interface FullScreenLoadingProps {
  label?: string;
}

const FullScreenLoading = ({ label }: FullScreenLoadingProps) => {
  return createPortal(
    <div
      className="vw-100 vh-100 position-fixed z-3 top-0"
      style={{ backdropFilter: 'blur(0.6rem)' }}
    >
      <div className="w-100 h-100 row">
        <div className=" col-md-12 d-flex justify-content-center align-items-center">
          <CircularLoading label={label} />
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default FullScreenLoading;
