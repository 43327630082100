import React from 'react';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';

import { EmbedContext } from './contexts';

import { NavBar, Footer, ScrollToTop } from './components';
import {
  Home,
  QuoteEdit,
  QuoteNew,
  Tutorial,
  About,
  Terms,
  Technology,
  Pricing,
  NotFound,
  Account,
} from './pages';
import './styles/App.css';

/**
 * Wrap a component to include route parameters in the
 * construction so we can keep `useParams` to the top
 * level and do argument passing.
 *
 * <Wrap Child={QuoteEdit}/>
 *
 * @param Component the component to wrap
 * @returns wrapped with parameters passed
 */
const Wrap = (props: any) => {
  // i.e. `/quotes/:quoteId` = {quoteId: "real-quote-id"}
  const params = useParams();
  // the component to create, i.e. `QuoteEdit`
  const { Child } = props;

  return <Child {...params} />;
};

export default () => (
  <BrowserRouter>
    <ScrollToTop />
    <div className="App">
      <EmbedContext.Consumer>
        {({ isEmbedded }) => !isEmbedded && <NavBar />}
      </EmbedContext.Consumer>
      <div className="App-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/help" element={<Tutorial />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/methods" element={<Pricing />} />
          <Route path="/technology" element={<Technology />} />

          <Route path="/new" element={<QuoteNew shopId="kerfed" />} />
          <Route path="/quotes/:quoteId" element={<Wrap Child={QuoteEdit} />} />
          <Route path="/shops/:shopId/" element={<Wrap Child={QuoteNew} />} />
          <Route path="/account" element={<Account />} />
          <Route path="/404" element={<NotFound code={404} />} />
          <Route path="/401" element={<NotFound code={401} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <EmbedContext.Consumer>
        {({ isEmbedded }) => !isEmbedded && <Footer />}
      </EmbedContext.Consumer>
    </div>
  </BrowserRouter>
);
