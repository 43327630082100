import React from 'react';
import { Link } from 'react-router-dom';
import { User } from 'firebase/auth';

interface ProfileMenuProps {
  user?: User;
  onLogout: () => void;
}

const ProfileMenu = ({ user, onLogout }: ProfileMenuProps) => {
  return (
    <div className="dropdown">
      <button
        className="user-button btn btn-outline-light dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {user?.displayName || user?.email || 'Unknown'}
      </button>
      <div
        className="dropdown-menu bg-dark"
        aria-labelledby="dropdownMenuButton"
        style={{ marginLeft: '-3rem' }}
      >
        <Link to="/account" className="dropdown-item">
          My Account
        </Link>
        <a onClick={onLogout} href="#" className="dropdown-item text-danger">
          Log Out
        </a>
      </div>
    </div>
  );
};

export default ProfileMenu;
