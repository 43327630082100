import React from 'react';

import FileImage from '../FileImage';
import Explainer from '../Explainer';
import { FormattedId } from '../formatting';
import CircularLoading from '../CircularLoading';

interface FileProps {
  fileId?: string;
  fileUrl?: string;
  fileName?: string;
  loading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const File = ({
  fileId,
  fileUrl,
  fileName,
  loading,
  isError,
  errorMessage,
}: FileProps) => {
  return (
    <div className="card h-100" title={errorMessage}>
      {loading ? (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <CircularLoading />
        </div>
      ) : (
        <>
          <FileImage url={fileUrl} />
          {isError || (!fileUrl && !loading) ? (
            <img className="p-2" src="/static/images/frown.svg" />
          ) : null}

          <div className="card-body">
            <h2 className="card-title">{fileName}</h2>

            <div className="id-text card-text ">
              <Explainer
                explanation={
                  'FID is the File ID, which may be referenced in drawings and invoices.'
                }
              >
                FID:&nbsp;
                <FormattedId id={fileId} />
              </Explainer>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default File;
