import React from 'react';

interface ErrorProps {
  title?: string;
  message?: string;
}

const Error = ({ title, message }: ErrorProps) => {
  return (
    <div className="container-md p-3 bg-white align-center">
      <div className="alert alert-secondary" role="alert">
        <img className="m-5 w-25" src="/static/images/frown.svg" />
        {!!title && <h1 className="alert-heading">{title}</h1>}
        {!!message && <p>{message}</p>}
      </div>
      <div className="segmentRectangle" />
    </div>
  );
};

export default Error;
