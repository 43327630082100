import React, { useMemo } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

export type TOptions = {
  value: string;
  label: React.ReactNode;
  hex?: string;
};

interface EditableProcessOptionProps {
  label: string;
  defaultValue?: string | null;
  options: TOptions[];
  selected?: string | null;
  onChange?: (value: string) => void;
}

const EditableProcessOption = ({
  label,
  options,
  selected = '',
  defaultValue = '',
  onChange,
}: EditableProcessOptionProps) => {
  const adjustedOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: (
        <span>
          {option?.hex ? (
            <span style={{ color: option.hex }}>&#9632;&nbsp;</span>
          ) : null}
          {option.label}
        </span>
      ),
    }));
  }, [options]);

  const selectedLabel = useMemo(() => {
    return (
      adjustedOptions.find((option) => option.value === selected)?.label || ''
    );
  }, [adjustedOptions, selected]);

  const handleChange = (value: string) => {
    if (onChange) onChange(value === selected ? '' : value);
  };

  return (
    <>
      <p>Configure {label}</p>
      <div className="part-config">
        <button
          type="button"
          className="btn dropdown-toggle"
          style={
            !selectedLabel
              ? {
                  backgroundColor: '#FFC107',
                  border: '0px',
                }
              : undefined
          }
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedLabel || `Select ${label}`}
        </button>

        <div className="dropdown-menu">
          {adjustedOptions.map((option) => (
            <button
              key={`${option.value}-process`}
              onClick={() => handleChange(option.value)}
              className={`dropdown-item ${option.value === selected && 'active'} ${option.value === defaultValue && 'dropdown-item-default'}`}
              type="button"
            >
              {option.label}
              {option.value === selected && (
                <span>
                  &nbsp;
                  <FaTimesCircle />
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditableProcessOption;
