import React from 'react';
import {
  signInWithPopup,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
} from 'firebase/auth';

import ProfileMenu from './ProfileMenu';
import LoginButton from './LoginButton';

import { auth } from 'common/src/api/firebase';
import { useFirebaseAuth } from 'app/src/hooks/useFirebaseAuth';

import '../../styles/UserButton.scss';

const UserButton = () => {
  const user = useFirebaseAuth();

  const onLogin = async () => {
    if (!user) {
      return;
    }
    const provider = new GoogleAuthProvider();
    // In memory persistence will be applied to the signed in Google user
    // even though the persistence was set to 'none' and a page redirect
    // occurred.
    try {
      await signInWithPopup(auth, provider, browserPopupRedirectResolver);
    } catch (e) {
      console.error(e);
    }
  };

  const onLogout = () => {
    auth.signOut();
  };

  if (!user) {
    return <div className="spinner-border text-light" role="status" />;
  }

  if (!user?.isAnonymous) {
    return <ProfileMenu onLogout={onLogout} user={user} />;
  }

  return <LoginButton onLogin={onLogin} />;
};

export default UserButton;
