import React, { useContext } from 'react';

import EditablePart from '../../components/EditablePart';
import FileList from '../../components/FileList';
import { FormattedId } from '../../components';
import { EmbedContext } from '../../contexts';
import { ErrorBoundary, LocationAsk } from '../../components';
import CircularLoading from 'app/src/components/CircularLoading';
import NotFound from '../NotFound';
import FullScreenLoading from 'app/src/components/FullScreenLoading';
import OrderCompleted from 'app/src/components/OrderCompleted';
import Error from 'app/src/components/Error';

import { useQuoteEditController } from './useQuoteEditController';

interface QuoteEditerProps {
  quoteId: string;
}

const QuoteEditer = ({ quoteId }: QuoteEditerProps) => {
  const { isEmbedded } = useContext(EmbedContext);
  const {
    loadingGetOrder,
    loadingScreen,
    loadingPartialUpdate,
    order,
    shop,
    errorGetOrder,
    stripeClientSecret,
    errorPlaceOrder,
    disabledPlaceOrder,
    errorPartialUpdate,
    failedGetPartList,
    onChangeMethod,
    onChangePartOrderData,
    onAddOrderFile,
    onChangeShipping,
    onPlaceOrder,
    onCancelPlaceOrder,
  } = useQuoteEditController({ quoteId });

  if (errorGetOrder?.name === 404 || errorGetOrder?.name === 401) {
    return <NotFound code={errorGetOrder?.name} />;
  }

  if (failedGetPartList) {
    return (
      <Error
        title="Failed Getting Part List"
        message="Please refresh the page to try again."
      />
    );
  }

  if (loadingGetOrder && !order) {
    return (
      <CircularLoading
        label={
          <>
            Quote #<FormattedId id={quoteId} />
          </>
        }
      />
    );
  }

  return (
    <div
      className={
        isEmbedded
          ? 'container-fluid bg-white py-3'
          : 'container-md bg-white p-3'
      }
      data-iframe-height="true"
    >
      <LocationAsk shopId={shop?.shop_id || ''} />

      {!!(order?.state as any)?.is_paid ? (
        <OrderCompleted order={order} shop={shop} />
      ) : (
        <div className="grid">
          <div className="row">
            <div className="col-md">
              <h1>
                Quote <FormattedId id={quoteId} />
              </h1>
            </div>
          </div>

          <div className="row py-4">
            <div className="col">
              <ErrorBoundary>
                {!!order?.models?.length && (
                  <FileList
                    isProcessing={!!(order?.state as any)?.is_processing}
                    models={order?.models}
                    onAddOrderFile={onAddOrderFile}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <ErrorBoundary>
                <EditablePart
                  order={order}
                  shop={shop}
                  clientSecret={stripeClientSecret}
                  errorPlaceOrder={errorPlaceOrder}
                  disabledPlaceOrder={disabledPlaceOrder}
                  loading={
                    loadingGetOrder ||
                    !!loadingScreen?.active ||
                    loadingPartialUpdate
                  }
                  resetToInitial={!!errorPartialUpdate}
                  onChangeMethod={onChangeMethod}
                  onChangePartOrderData={onChangePartOrderData}
                  onChangeShipping={onChangeShipping}
                  onPlaceOrder={onPlaceOrder}
                  onCancelPlaceOrder={onCancelPlaceOrder}
                />
              </ErrorBoundary>
            </div>
          </div>

          {isEmbedded ? null : <div className="segmentRectangle" />}
        </div>
      )}

      {!!loadingScreen?.active && (
        <FullScreenLoading label={loadingScreen?.message} />
      )}
    </div>
  );
};

export default QuoteEditer;
