import React from 'react';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Modal from '../../Modal';

import config from 'config/src/config-public.json';

interface StripeCheckoutProps {
  clientSecret?: string;
  onCancel?: () => void;
}

const StripeCheckout = ({ clientSecret, onCancel }: StripeCheckoutProps) => {
  const stripePromise = loadStripe(config.stripe.public_key);

  if (!clientSecret) return null;

  return (
    <Modal
      id="stripeCheckoutModal"
      title="Checkout"
      size="modal-xl"
      open={!!clientSecret}
      onClose={onCancel}
      style={{
        backdropFilter: 'blur(0.6rem)',
      }}
    >
      <div id="checkout">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </Modal>
  );
};

export default StripeCheckout;
