import { auth } from 'common/src/api/firebase';
import config_public from 'config/src/config-public.json';

const baseUri = `${config_public?.kerfed?.api_url || 'api'}/v1`;

// default messages for each response status code.
const statusMessages = {
  304: 'Resource not modified.',
  401: 'Unauthorized to access resource!',
  404: "Requested resource doesn't exist.",
};

export const ResponseError = async (res: Response) => {
  /**
   * Format a response that didn't succeed into an Error-like object
   * that has `name` and `message` properties so we can correctly
   * handle different response codes upstream.
   */
  let message;
  try {
    // may not exist or may not be JSON

    const blob = await res.json();
    // functions can respond with a 'message' or 'error' field
    message = blob.message || blob.error;
  } catch (error) {
    console.error({ error, res });
  } finally {
    return {
      name: res.status,
      message: message || statusMessages[res.status] || 'Unexpected error!',
    };
  }
};

// Make an authenticated call to a Firebase HTTP Cloud Function.
export const callAuthenticated = async ({
  uri,
  method,
  queries,
  params,
  dateSince,
  attempt,
}: {
  uri: string;
  method: string;
  queries?: { [key: string]: string | number };
  params?: any;
  dateSince?: Date;
  attempt?: number;
}) => {
  const wait = (ms) => new Promise((r) => setTimeout(r, ms));

  // TODO (MDH)
  // the token should probably be passed in to this function
  // the correct place to get it would be in `useCurrentUserQuery`
  const token = await auth?.currentUser?.getIdToken();

  if (!token) {
    if (attempt && attempt > 5) {
      console.error(`Must be logged in to make authenticated call to ${uri}.`);
      return;
    } else {
      // wait longer between tries
      await wait(1000 * (attempt || 1));
      return callAuthenticated({
        uri,
        method,
        queries,
        params,
        dateSince,
        attempt: (attempt || 0) + 1,
      });
    }
  }
  //const bearerToken = await currentUser.getIdToken();
  const fullQueries = queries || {};

  // Create request headers with authentication.
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  if (dateSince) {
    headers['If-Modified-Since'] = dateSince.toUTCString();
  }

  // Append a link token to the call if one is present in the URI.
  const urlParams = new URLSearchParams(window.location.search);
  const linkToken = urlParams.get('token');
  if (linkToken) {
    fullQueries.token = linkToken;
  }

  // Construct the query string.
  const queryString = Object.entries(fullQueries)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const fullUri = queryString ? `${uri}?${queryString}` : uri;

  // Retry the call several times before returning failure.
  return fetch(fullUri, {
    method,
    headers,
    body: JSON.stringify(params),
  });
};

// accept any "ok" status code
export const statusBad = (res?: Response) =>
  !res || res.status < 200 || res.status >= 300;

type ProgressCallback = (loaded: number, total: number) => any;

/**
 * Uploads a file using the REST API.
 *
 * This generates an upload ID, gets a signed URL to use for
 * uploading to that upload ID, performs the upload, then returns the ID.
 * @param file
 */
export const uploadFile = async (
  file: File,
  onProgress?: ProgressCallback,
): Promise<string> => {
  // Make an initial call to generate an upload ID.
  const uri = `${baseUri}/upload`;
  const content_type = file.type || 'application/octet-stream';
  const resCreate = await callAuthenticated({
    uri,
    method: 'POST',
    params: {
      file_name: file.name,
      content_type,
    },
  });

  if (statusBad(resCreate)) {
    throw await ResponseError(resCreate);
  }
  const { file_id, upload_url } = await resCreate.json();

  // Upload the file to the URL.
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', upload_url, true);
    // xhr.setRequestHeader('x-goog-meta-filename', file.name);
    // xhr.setRequestHeader('Content-Type', contentType);

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = (evt) => {
        if (!evt.lengthComputable) {
          return;
        }
        onProgress(evt.loaded, evt.total);
      };
    }

    xhr.onload = () =>
      // accept any status code in the success range
      xhr.status >= 200 && xhr.status < 300
        ? res(file_id)
        : rej(new Error(xhr.responseText || 'Network request failed'));

    xhr.onerror = () =>
      rej(new Error(xhr.responseText || 'Network request failed'));

    xhr.send(file);
  });
};
