import React from 'react';
import { Money } from '@kerfed/client';

interface SafePriceProps {
  price?: Money | null;
  message?: string;
  text?: string;
}

/**
 *
 * @param money value to format
 * @returns
 */
const money_format = (money?: Money): string | undefined => {
  if (!money || money?.amount === 0 || !Number.isFinite(money?.amount)) {
    return undefined;
  }

  // format USD prices
  if (money?.currency?.toLowerCase() === 'usd') {
    return `$${((money?.amount || 0) / 100.0).toFixed(2)}`;
  }
  return `$${((money?.amount || 0) / 100.0).toFixed(2)} ${money?.currency}`;
};

const SafePrice = ({
  price,
  message,
  text = 'Manual Quoting Required',
}: SafePriceProps) => {
  const fmt = money_format(price | undefined);
  if (fmt === undefined) {
    return (
      <div
        style={{
          textDecoration: 'underline dotted',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        title={message || ''}
      >
        {text}
      </div>
    );
  }

  // format USD prices
  if (price?.currency?.toLowerCase() === 'usd') {
    return <>${((price?.amount || 0) / 100.0).toFixed(2)}</>;
  }

  return (
    <>
      {price?.currency} {((price?.amount || 0) / 100.0).toFixed(2)}
    </>
  );
};

export default SafePrice;
