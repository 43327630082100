import React from 'react';
import { Money, PostalAddress } from '@kerfed/client';

import AddressForm from './AddressForm';

interface ShippingProps {
  address?: PostalAddress | null;
  shippingCost?: Money | null;
  resetToInitial?: boolean;
  onChange?: (address: Partial<PostalAddress>) => void;
}

const Shipping = ({
  address,
  shippingCost,
  resetToInitial,
  onChange,
}: ShippingProps) => {
  return (
    <tr>
      <th colSpan={2}>
        <h2>Shipping Address</h2>
        <div className="mt-3">
          <AddressForm
            data={address}
            resetToInitial={resetToInitial}
            onChange={onChange}
          />
        </div>
      </th>
    </tr>
  );
};

export default Shipping;
