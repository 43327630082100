import React, { useMemo } from 'react';

interface PlaceOrderButtonProps {
  title?: string;
  disabled?: boolean;
  error?: string;
  loading?: boolean;
  onClick?: () => void;
}

const PlaceOrderButton = ({
  title,
  disabled,
  loading,
  error,
  onClick,
}: PlaceOrderButtonProps) => {
  const buttonTitle = useMemo(() => {
    if (loading) {
      return 'Calculating price!';
    }

    if (disabled) {
      if (title) return title;
      return 'Not all parts have configured prices! Finish configuring them to place order.';
    }

    return '';
  }, [loading, disabled, title]);

  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      <button
        className="btn btn-lg btn-primary"
        disabled={disabled}
        title={buttonTitle}
        style={{
          pointerEvents: 'auto',
          cursor: disabled ? 'default' : 'pointer',
        }}
        onClick={onClick}
      >
        Place Order &#8594;
      </button>
      {!!error && <div className="alert alert-danger my-0">{error}</div>}
    </div>
  );
};

export default PlaceOrderButton;
