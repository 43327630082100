import React, { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  AiOutlineCloudUpload,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from 'react-icons/ai';

import UnitSelection, { TFileWithUnit } from './UnitSelection';

import { uploadFile } from '../api/utils/common';
import { generateId, getFileExtension } from 'common/src/utils';

import { FILE_REQUIRED_UNIT, SUPORTED_FILE_TYPES } from '../constants';

type FileUpload = {
  name: string;
  loaded: number;
  total: number;
  success?: boolean;
  error?: Error;
};

const Upload: React.FC<{ upload: FileUpload }> = ({
  upload: { name, loaded, total, success, error },
}) => (
  <div>
    <hr />
    <h2>
      {name}
      {success && <AiFillCheckCircle style={{ color: 'green' }} />}
      {error && <AiFillCloseCircle style={{ color: 'red' }} />}
    </h2>
    <div className="progress">
      <div
        className="progress-bar progress-bar-striped"
        role="progressbar"
        style={{
          width: `${success || error ? 100.0 : (loaded / total) * 100.0}%`,
        }}
        aria-valuenow={success || error ? 100.0 : (loaded / total) * 100.0}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  </div>
);

export type TPathWithUnit = {
  path: string;
  unit: string;
};

type Props = {
  onUploads?: (paths: TPathWithUnit[]) => Promise<any>;
  sparse?: boolean;
};

export const FileUploader: React.FC<Props> = ({ onUploads, sparse }) => {
  const [isError, setIsError] = useState(false);
  const [uploads, setUploads] = useState<{ [key: string]: FileUpload }>({});
  const [openUnitSelectionModal, setOpenUnitSelectionModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleCloseUnitSelection = () => {
    setOpenUnitSelectionModal(false);
  };

  const handleUpload = useMemo(
    () => async (files: TFileWithUnit[]) => {
      // Upload a new file to the user's upload directory and make an API call to
      // add this file to a quote.
      const uploadTasks = files.map(async (file) => {
        const fileId = generateId();

        // Create a helper to update progress for this file.
        const setProgress = (progress) =>
          setUploads((newUploads) => ({
            ...newUploads,
            [fileId]: {
              ...progress,
              name: file.file.name,
            },
          }));

        try {
          // Try to upload a file and report progress along the way.
          setProgress({ loaded: 0, total: file.file.size });
          const uploadId = await uploadFile(file.file, (loaded, total) => {
            setProgress({ loaded, total });
          });
          setProgress({
            loaded: file.file.size,
            total: file.file.size,
            success: !!uploadId,
          });
          return { uploadId, fileId, unit: file.unit };
        } catch (error) {
          // If the upload fails with an error, mark and report it.
          console.error(error);
          setProgress({ success: false, error });
          return { fileId };
        }
      });

      // Process a list of only the successful uploads.
      // This filter removes all upload IDs with falsey values (i.e. undefined or empty)
      const uploadResults = await Promise.all(uploadTasks);

      // Remove the successfully uploaded files from the list.
      setUploads((prevUploads) => {
        const newUploads = { ...prevUploads };
        for (const { fileId } of uploadResults.filter((v) => v?.uploadId)) {
          delete newUploads[fileId];
        }
        return newUploads;
      });

      // If handler is registered, pass it the successful IDs.
      const uploadResult: TPathWithUnit[] = uploadResults
        .filter((res) => !!res.uploadId)
        .map((res) => ({
          path: res.uploadId || '',
          unit: res.unit || '',
        }));

      if (onUploads) {
        await onUploads(uploadResult);
      }
    },
    [onUploads, setUploads],
  );

  const onDropAccepted = useMemo(
    () => async (files: File[]) => {
      const hasUnsuporrtedFile = files.some((file) => {
        const fileExtension = getFileExtension(file);
        return !SUPORTED_FILE_TYPES.includes(fileExtension.toUpperCase());
      });

      if (hasUnsuporrtedFile) return setIsError(true);
      else setIsError(false);

      const hasFileRequiredUnit = files.some((file) => {
        const fileExtension = getFileExtension(file);
        return FILE_REQUIRED_UNIT.includes(fileExtension.toUpperCase());
      });

      if (hasFileRequiredUnit) {
        setSelectedFiles(files);
        setOpenUnitSelectionModal(true);
        return;
      }

      await handleUpload(files.map((file) => ({ file, unit: '' })));
    },
    [handleUpload],
  );

  const handleSubmitFileAfterUpdateUnit = async (files: TFileWithUnit[]) => {
    setOpenUnitSelectionModal(false);
    await handleUpload(files);
  };

  return (
    <>
      <Dropzone onDropAccepted={(files) => onDropAccepted(files)}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <div>
              <input {...getInputProps()} />
              {sparse ? (
                Object.keys(uploads).length > 0 ? (
                  <div className="spinner-border my-2" role="status" />
                ) : (
                  <p>
                    Upload {SUPORTED_FILE_TYPES.slice(0, 4).join(', ')}, etc.
                  </p>
                )
              ) : (
                <div>
                  <AiOutlineCloudUpload
                    style={{ width: '5em', height: 'auto' }}
                  />
                  <br />
                  <p>Click or drag and drop files here to upload.</p>
                  <p>
                    <strong>
                      Most CAD:
                      <br />
                    </strong>
                    File {String.fromCharCode(0x2192)} Save As{' '}
                    {String.fromCharCode(0x2192)} <strong>STEP</strong>
                  </p>
                  <p>
                    <strong>Supported formats:</strong>
                    <br />
                    {SUPORTED_FILE_TYPES.join(', ')}
                  </p>
                  {Object.keys(uploads).length > 0 && (
                    <div className="container-md">
                      <ul>
                        {Object.entries(uploads).map(([fileId, upload]) => (
                          <Upload key={fileId} upload={upload} />
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
            {isError && (
              <div className="alert alert-danger rounded-0 mb-0" role="alert">
                <p className="alert-heading mb-0">Unsupported File Type</p>
                {!!sparse && (
                  <p className="fw-normal">
                    The supported file types are:{' '}
                    {SUPORTED_FILE_TYPES.join(', ')}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </Dropzone>

      <UnitSelection
        open={openUnitSelectionModal}
        files={selectedFiles}
        onClose={handleCloseUnitSelection}
        onSubmit={handleSubmitFileAfterUpdateUnit}
      />
    </>
  );
};

export default FileUploader;
