import React, { useMemo, useState } from 'react';
import { Money, Postprocess, Shop } from '@kerfed/client';
import { TOrder } from '@kerfed/types';

import SafePrice from '../SafePrice';
import GeometryImage from '../EditablePart/GeometryImage';
import { FormattedId } from '../formatting';

import { htmlToUnicode, titleCase } from '@kerfed/common/utils';

import { MAP_METHOD } from 'app/src/constants';

interface OrderCompletedProps {
  order?: TOrder;
  shop?: Shop;
}

type TPart = {
  partNumber: string;
  partName: string;
  method: string;
  quantity?: number;
  unitPrice?: Money | null;
  totalPrice?: Money | null;
  data?: {
    geomId: string;
    material: string;
    thickness: string;
    finish: string;
    color: string;
    postprocessing: string[];
  };
};

const OrderCompleted = ({ order, shop }: OrderCompletedProps) => {
  const [expandedId, setExpandedId] = useState('');

  const orderParts: TPart[] = useMemo(() => {
    if (!order?.lines) return [];
    const parts: TPart[] = [];

    const finishValue = shop?.descriptions?.['finish_id'] || {};
    const colorValue = shop?.descriptions?.['color_id'] || {};

    for (const line of order.lines) {
      const part = order.parts?.find(
        (part) => part?.part_id === line?.config?.part_id,
      );
      if (!part) continue;

      const geom = order.models?.find((model) =>
        model.geometries?.some((g) => g?.geom_id === part.geom_id),
      );
      if (!geom) continue;

      const material = titleCase(line.config?.material_id || '');
      const thickness = titleCase(line.config?.thickness_id || '');
      const finish =
        finishValue[line.config?.finish_id || '']?.label ||
        titleCase(line.config?.finish_id || '');
      const color =
        colorValue[line.config?.color_id || '']?.label ||
        titleCase(line.config?.color_id || '');

      const methodId = line.config?.method_id || '';
      const rawPosts: Postprocess[] =
        (part as any)?.[MAP_METHOD?.[methodId]]?.posts || [];
      const posts: string[] = [];

      for (const post of rawPosts) {
        if (line.config?.posts?.includes(post.post_id || '')) {
          posts.push(
            htmlToUnicode(
              `${post?.group ? `${post.group}: ` : ''}${post.description}`,
            ),
          );
        }
      }

      parts.push(
        ...[
          {
            partNumber: part.geom_id || '',
            partName: geom?.original?.name || '',
            method: MAP_METHOD?.[line.config?.method_id || ''] || '',
            quantity: line?.price?.quantity || 0,
            unitPrice: line.price?.unit,
            totalPrice: line.price?.total,
            data: {
              geomId: part.geom_id || '',
              material: material,
              thickness: thickness,
              finish: finish,
              color: color,
              postprocessing: posts,
            },
          },
        ],
      );
    }

    return parts;
  }, [order, shop]);

  const handleExpandRow = (partNumber: string) => {
    setExpandedId((prev) => (prev === partNumber ? '' : partNumber));
  };

  if (!order) return null;

  return (
    <div className="contianer">
      <div>
        <p className="fs-5 m-0 pb-3">
          Order #<FormattedId id={order.order_id || ''} />
        </p>
        <p className="fw-bolder fs-1 mb-0 lh-sm">Thank you for your order!</p>
        <p className="fs-6 mb-0">
          We will send an email to confirm your order details.
        </p>
      </div>

      <div className="row m-0 p-0">
        <div className="col-2 col-lg-1 m-0 p-0">
          <hr />
        </div>
      </div>

      <div className="pt-4">
        <p className="fs-5 fw-bold mb-2">Order Details</p>
      </div>

      <div className="row">
        <div className="col-12 col-lg-7">
          <div className="table-responsive bordered mb-3">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th className="fs-6 align-middle bg-seablue">Part Number</th>
                  <th className="fs-6 align-middle bg-seablue">Part Name</th>
                  <th className="fs-6 align-middle bg-seablue">Method</th>
                  <th className="fs-6 align-middle bg-seablue text-center">
                    Quantity
                  </th>
                  <th className="fs-6 align-middle bg-seablue">Unit Price</th>
                  <th className="fs-6 align-middle bg-seablue">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {orderParts.map((part, index) => (
                  <>
                    <tr key={`${index}`}>
                      {!!part.partNumber && (
                        <td>
                          {part.partNumber ? (
                            <FormattedId id={part.partNumber} />
                          ) : (
                            ''
                          )}
                        </td>
                      )}
                      <td>
                        <div
                          role={
                            part.data && part.partNumber ? 'button' : undefined
                          }
                          className={
                            part.data && part.partNumber
                              ? 'text-decoration-underline text-primary'
                              : ''
                          }
                          onClick={
                            part.data && part.partNumber
                              ? () => handleExpandRow(part.partNumber)
                              : undefined
                          }
                        >
                          {part.partName}
                        </div>
                      </td>
                      <td className="text-capitalize">{part.method}</td>
                      {!!part.quantity && (
                        <td className="text-center">{part.quantity}</td>
                      )}
                      <td className={part.quantity ? 'fw-bold' : 'fw-normal'}>
                        <SafePrice price={part.unitPrice} text="" />
                      </td>
                      {part.totalPrice && (
                        <td>
                          <strong>
                            <SafePrice price={part.totalPrice} text="" />
                          </strong>
                        </td>
                      )}
                    </tr>

                    {part.partNumber === expandedId && !!part.data && (
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-5">
                              <GeometryImage
                                geomId={part.data.geomId}
                                models={order.models}
                              />
                            </div>
                            <div className="col-7">
                              <ul className="description-list">
                                {Object.keys(part.data)
                                  .filter(
                                    (key) =>
                                      key !== 'geomId' &&
                                      part?.data?.[key]?.length,
                                  )
                                  .map((key) => (
                                    <li key={key} className="mb-2">
                                      <p className="fs-6 text-capitalize fw-bolder mb-0">
                                        {key}
                                      </p>
                                      {key === 'postprocessing' ? (
                                        <ul className="ps-3">
                                          {part?.data?.[key].map(
                                            (post, index) => (
                                              <li
                                                key={`${index}`}
                                                className="mb-1"
                                              >
                                                {post}
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      ) : (
                                        <p className="fs-6 text-capitalize mb-0">
                                          {part?.data?.[key] || ''}
                                        </p>
                                      )}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                <tr>
                  <td className="text-end" colSpan={5}>
                    Total
                  </td>
                  <td>
                    <strong>
                      <SafePrice price={order.total?.total} text="" />
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-12 col-lg-5">
          <div className="sticky-lg-top">
            <div className="table-responsive bordered mb-3">
              <table className="table table-bordered mb-0">
                <tbody>
                  <tr>
                    <td className="bg-seablue" style={{ width: '35%' }}>
                      Address
                    </td>
                    <td className="w-100">
                      <div>{order.shipping?.city || ''}</div>
                      <div>{order.shipping?.region || ''}</div>
                      <div>{order.shipping?.country || ''}</div>
                      <div>{order.shipping?.postal_code || ''}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-seablue">Payment Status</td>
                    <td className="align-middle">
                      <strong>Paid</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCompleted;
