import React from 'react';
import { PartConfiguration, AddFabrication } from '@kerfed/client';

import FileImage from 'app/src/components/FileImage';
import EditablePartConfiguration from '../EditablePartConfiguration';

import { EditablePartProps } from './types';

interface EditableAddPartProps extends EditablePartProps {
  data: AddFabrication;
}

const EditableAddPart = ({
  partId,
  data,
  shop,
  config,
  onChangePartOrderData,
}: EditableAddPartProps) => {
  const handleChangePartOrderData = (newConfig: PartConfiguration) => {
    onChangePartOrderData(partId, newConfig);
  };

  return (
    <div>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <EditablePartConfiguration
              posts={data.posts}
              shop={shop}
              defaultConfig={data.defaults}
              config={config || data.defaults}
              onChangePartOrderData={handleChangePartOrderData}
            />
          </div>
          <div className="col-md">
            {data?.drawing?.url && <FileImage url={data.drawing.url} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableAddPart;
