import { Shop } from '@kerfed/client';

import { fetcher } from '../utils/fetcher';

import { TDefaultApiParams } from '../types';

export const useAPIGetShop = ({
  onCompleted,
  onError,
}: TDefaultApiParams<Shop>) => {
  const [onGetShop, { loading }] = fetcher<Shop, any>({
    uri: `shops/shopId`,
    variables: {
      options: { method: 'GET' },
    },
    onCompleted,
    onError,
  });

  return { onGetShop, loading };
};
