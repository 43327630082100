import React, { useEffect, useState } from 'react';

import Modal from '../Modal';

import { getFileExtension } from '@kerfed/common/utils';

import { FILE_REQUIRED_UNIT, UNITS } from 'app/src/constants';

export type TFileWithUnit = {
  file: File;
  unit: string;
};

interface UnitSelectionProps {
  open: boolean;
  files?: File[];
  onClose?: () => void;
  onSubmit?: (files: TFileWithUnit[]) => void;
}

const UnitSelection = ({
  open,
  files = [],
  onClose,
  onSubmit,
}: UnitSelectionProps) => {
  const [fileUnits, setFileUnits] = useState<TFileWithUnit[]>([]);

  const handleChangeUnit = (newUnit: string, position: number) => {
    setFileUnits((prev) =>
      prev.map((file, index) => {
        if (index === position) {
          return {
            ...file,
            unit: newUnit,
          };
        }
        return file;
      }),
    );
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(fileUnits);
  };

  useEffect(() => {
    if (open && files.length) {
      const fileWithUnits: TFileWithUnit[] = files.map((file) => {
        const fileExtension = getFileExtension(file);
        const requiredUnit = FILE_REQUIRED_UNIT.includes(
          fileExtension.toUpperCase(),
        );

        return {
          file,
          unit: requiredUnit ? 'Automatic' : '',
        };
      });

      setFileUnits(fileWithUnits);
    }
  }, [files, open]);

  return (
    <Modal
      id="unitSelectionModal"
      title="Select Unit"
      size="modal-lg"
      open={open}
      onClose={onClose}
      footer={
        <button
          type="button"
          className="btn btn-primary"
          data-bs-dismiss="modal"
          onClick={handleSubmit}
        >
          Continue
        </button>
      }
    >
      <div className="text-start text-body">
        <p className="fs-6 text fw-normal text-reset p-0 pb-3">
          You've uploaded a file in a format which doesn't always have units.
          Select an option or leave it on automatic and we'll try to guess.
        </p>

        {fileUnits.map((file, index) => {
          if (!file.unit) return null;
          return (
            <div className="row align-items-start pb-2" key={`${index}`}>
              <div className="col-7">
                <strong>{file.file.name}</strong>
              </div>
              <div className="col-5 part-config-group pb-0">
                <div className="part-config">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {file.unit}
                  </button>
                  <div className="dropdown-menu dropdown-menu-custom position-fixed">
                    {UNITS.map((unit) => (
                      <button
                        key={`${unit}-unit`}
                        onClick={() => handleChangeUnit(unit, index)}
                        className={`dropdown-item ${unit === file.unit && 'active'}`}
                        type="button"
                      >
                        {unit}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default UnitSelection;
