import { PostalAddress } from '@kerfed/client';
import {
  TCreateOrderReq,
  TCreateOrderRes,
  TOrder,
  TOrderEmailReq,
  TOrderFileAddReq,
  TOrderLineEditReq,
  TOrderLineEditRes,
  TOrderStripeReq,
  TOrderStripeRes,
} from '@kerfed/types';

import { fetcher } from '../utils/fetcher';

import { TDefaultApiParams } from '../types';

export const useAPIGetOrder = ({
  onCompleted,
  onError,
}: TDefaultApiParams<TOrder>) => {
  const [onGetOrder, { loading, error }] = fetcher<TOrder, any>({
    uri: `orders/orderId`,
    variables: {
      options: { method: 'GET' },
    },
    onCompleted,
    onError,
  });

  return { onGetOrder, loading, error };
};

export const useAPIOrderLineEdit = ({
  onCompleted,
  onError,
}: TDefaultApiParams<TOrderLineEditRes>) => {
  const [onOrderLineEdit, { loading, error }] = fetcher<
    TOrderLineEditRes,
    TOrderLineEditReq
  >({
    uri: `orders/orderId/price/lineId`,
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderLineEdit, loading, error };
};

export const useAPIOrderFileAdd = ({
  onCompleted,
  onError,
}: TDefaultApiParams<any>) => {
  const [onOrderFileAdd, { loading }] = fetcher<any, TOrderFileAddReq>({
    uri: `orders/orderId/file`,
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderFileAdd, loading };
};

export const useAPIOrderShipping = ({
  onCompleted,
  onError,
}: TDefaultApiParams<any>) => {
  const [onOrderShipping, { loading, error }] = fetcher<any, PostalAddress>({
    uri: 'orders/orderId/shipping',
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderShipping, loading, error };
};

export const useAPIOrderStripe = ({
  onCompleted,
  onError,
}: TDefaultApiParams<TOrderStripeRes>) => {
  const [onOrderStripe, { loading }] = fetcher<
    TOrderStripeRes,
    TOrderStripeReq
  >({
    uri: 'orders/orderId/stripe',
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderStripe, loading };
};

export const useAPIOrderEmail = ({
  onCompleted,
  onError,
}: TDefaultApiParams<any>) => {
  const [onOrderEmail, { loading }] = fetcher<any, TOrderEmailReq>({
    uri: 'orders/orderId/email',
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onOrderEmail, loading };
};

export const useAPICreateOrder = ({
  onCompleted,
  onError,
}: TDefaultApiParams<TCreateOrderRes>) => {
  const [onCreateOrder, { loading }] = fetcher<
    TCreateOrderRes,
    TCreateOrderReq
  >({
    uri: 'orders',
    variables: {
      options: { method: 'POST' },
    },
    onCompleted,
    onError,
  });

  return { onCreateOrder, loading };
};
