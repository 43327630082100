import React, { useEffect, useRef } from 'react';

interface ModalProps {
  id?: string;
  open?: boolean;
  title?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'modal-sm' | 'modal-md' | 'modal-lg' | 'modal-xl';
  style?: React.CSSProperties;
  onClose?: () => void;
}

const Modal = ({
  id,
  open,
  title = '',
  children,
  footer,
  size = 'modal-md',
  style,
  onClose,
}: ModalProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [open]);

  return (
    <div>
      <button
        ref={buttonRef}
        type="button"
        className="btn btn-primary visually-hidden"
        data-bs-toggle="modal"
        data-bs-target={`#${id}`}
      />

      <div
        id={id}
        className={`modal fade ${size}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-modal="true"
        style={style}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable pb-2">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-body" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              />
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">{footer}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
