import React from 'react';
import { useNavigate } from 'react-router-dom';

import FileUploader, { TPathWithUnit } from './FileUploader';

import { useAPICreateOrder } from '../api/order';
import { MAP_UNIT } from '../constants';

type Props = {
  shopId: string;
  sparse?: boolean;
};

export default ({ shopId, sparse }: Props) => {
  const navigate = useNavigate();

  const { onCreateOrder, loading } = useAPICreateOrder({
    onCompleted: (res) => {
      navigate(`/quotes/${res.order_id}`);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const onUploads = async (files: TPathWithUnit[]) => {
    if (!files.length) return;

    onCreateOrder({
      variables: {
        params: {
          shop_id: shopId,
          files: files.map((file) => ({
            file_id: file.path,
            units: MAP_UNIT?.[file.unit] || undefined,
          })),
        },
      },
    });
  };

  return (
    <div data-iframe-height="true" className="dropzone quoteUploader">
      {loading ? (
        <div data-iframe-height="true" className="loader p-2">
          <div className="spinner-border " role="status" />
          <p>Creating Quote</p>
        </div>
      ) : (
        <FileUploader onUploads={onUploads} sparse={sparse} />
      )}
    </div>
  );
};
