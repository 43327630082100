import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';

import { EmbedContext } from '../contexts';
import { QuoteUploader } from '../components';

import { auth } from 'common/src/api/firebase';

export default (props: any) => {
  // extract the parameters from the current URL
  const shopId = props?.shopId ?? 'kerfed';
  // are we embedded in an iFrame
  const { isEmbedded } = useContext(EmbedContext);

  useEffect(() => {
    /**
     * Force the user to sign-in anonymously if embedded
     */
    if (isEmbedded) signInAnonymously(auth);
  }, [isEmbedded]);

  return (
    <div
      className={
        isEmbedded
          ? 'container-fluid bg-white py-3'
          : 'container-fluid bg-white'
      }
      data-iframe-height="true"
    >
      {isEmbedded ? (
        <QuoteUploader shopId={shopId} />
      ) : (
        <div className="grid">
          <div className="row">
            <div className="col-lg-4 hidden-lg-down new-leftpanel" />
            <div className="col-md-5">
              <div className="p-3">
                <div className="grid">
                  <div className="row">
                    <div className="col-md">
                      <h1>New Instant Quote</h1>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-md-9">
                      <p>
                        Upload a single part, or a full assembly with multiple
                        parts from either 2D or 3D CAD. We'll do the analysis
                        and get you a{' '}
                        {shopId == 'kerfed' && (
                          <Link to="/methods">competitive</Link>
                        )}{' '}
                        quote on one (or hundreds) of parts in under a minute.
                      </p>
                    </div>
                  </div>
                  <div className="row my-3 NonSelectable">
                    <div className="col-md">
                      <QuoteUploader shopId={shopId} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
