import React, { useMemo } from 'react';
import { PostalAddress } from '@kerfed/client';

import InputGeoSuggest from '../InputGeoSuggest';

import { TConvertedGmapsAddress } from '../../types';

interface AddressFormProps {
  data?: PostalAddress | null;
  resetToInitial?: boolean;
  onChange?: (address: Partial<PostalAddress>) => void;
}

const AddressForm = ({ data, resetToInitial, onChange }: AddressFormProps) => {
  const initialGeoAddress = useMemo(() => {
    if (!data) return '';
    let address = data?.address_lines?.join(', ') || '';
    const addressKeys = ['city', 'region', 'country', 'zip', 'postal_code'];
    for (const key of addressKeys) {
      if (!data?.[key]) continue;
      const addressPart = address ? `, ${data[key]}` : data[key];
      address = address + addressPart;
    }

    return address;
  }, [data]);

  const handleChangeGeoSuggest = (address?: TConvertedGmapsAddress) => {
    if (onChange) {
      const addressLine = [
        ...(address?.line1 ? [address.line1] : []),
        ...(address?.line2 ? [address.line2] : []),
      ];

      onChange({
        latitude: address?.latlon?.[0],
        longitude: address?.latlon?.[1],
        city: address?.city,
        place_id: address?.place_id,
        country: address?.country,
        postal_code: address?.zip,
        region: address?.state,
        address_lines: addressLine?.length ? addressLine : undefined,
      });
    }
  };

  return (
    <div className="grid">
      <div className="row">
        <div className="col-md">
          <InputGeoSuggest
            error={!data?.place_id}
            initialValue={initialGeoAddress}
            resetToInitial={resetToInitial}
            onChange={handleChangeGeoSuggest}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
