import React from 'react';

import { validateEmail } from '@kerfed/common/utils';

interface EmailFormProps {
  emails: string[];
  note: string;
  error?: boolean;
  message?: string;
  success?: boolean;
  loading?: boolean;
  onChangeNote?: (note: string) => void;
  onChangeEmail?: (email: string, position: number) => void;
}

const EmailForm = ({
  emails,
  note,
  error,
  message,
  loading,
  onChangeNote,
  onChangeEmail,
}: EmailFormProps) => {
  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChangeNote) onChangeNote(e.target.value);
  };

  const handleChangeEmail =
    (position: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeEmail) onChangeEmail(e.target.value, position);
    };

  return (
    <div>
      <div data-iframe-height="true">
        <p className="mb-1">Enter each recipient's email address:</p>

        {emails.map((email, index) => (
          <div key={`${index}`}>
            <div className="input-group mb-1">
              <input
                type="text"
                className="form-control rounded-0"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                disabled={loading}
                value={email}
                placeholder="user@example.com"
                autoComplete="email"
                style={{
                  backgroundColor:
                    !!email && !validateEmail(email)
                      ? 'rgba(255, 0, 0, 0.1)'
                      : 'unset',
                }}
                onChange={handleChangeEmail(index)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="my-2">
        <p className="mb-1">Enter any additional notes or information here:</p>
        <textarea
          className="form-control rounded-0"
          value={note}
          onChange={handleChangeNote}
          disabled={loading}
          placeholder="These are the parts for project X, please bill them to account Y."
          rows={3}
        ></textarea>
      </div>

      {!!error && (
        <div className="alert alert-danger rounded-0" role="alert">
          <h2 className="alert-heading">
            There was an error when creating your RFQ
          </h2>
          <p>
            Check that the email addresses are typed correctly and verify that
            the configured quote has a valid price.
          </p>
          {message ? <p>{message}</p> : null}
        </div>
      )}
    </div>
  );
};

export default EmailForm;
