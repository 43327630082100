import React, { useEffect, useRef, useState } from 'react';
import Geosuggest, { Location } from '@ubilabs/react-geosuggest';
import { Helmet } from 'react-helmet-async';
import { toAddress } from '@kerfed/common/utils';

import config_public from 'config/src/config-public.json';
import { TConvertedGmapsAddress } from '../../types';

import '../../../../styles/Geosuggest.scss';

const API_KEY = config_public?.google?.maps_api;

interface InputGeoSuggestProps {
  error?: boolean;
  initialValue?: string;
  resetToInitial?: boolean;
  onChange?: (address?: TConvertedGmapsAddress) => void;
}

const InputGeoSuggest = ({
  error = false,
  initialValue,
  resetToInitial,
  onChange,
}: InputGeoSuggestProps) => {
  const countRenderInitial = useRef(0);
  const [_initialValue, setInitialValue] = useState<string>();
  const [loaded, setLoaded] = useState(!!window?.google?.maps);
  window.onGoogleMap = () => setLoaded(true);

  const handleSelectSuggest = (suggest?: Location) => {
    setInitialValue(undefined);
    if (onChange) onChange(suggest ? toAddress(suggest?.gmaps) : undefined);
  };

  useEffect(() => {
    if (countRenderInitial.current === 0) {
      setInitialValue(initialValue);
    } else {
      setInitialValue(undefined);
    }

    countRenderInitial.current = countRenderInitial.current + 1;
  }, [initialValue]);

  useEffect(() => {
    if (resetToInitial) {
      setInitialValue(initialValue);
    }
  }, [resetToInitial, initialValue]);

  if (!loaded) {
    return (
      <>
        <Helmet>
          <script
            async={true}
            defer={true}
            type="text/javascript"
            src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=onGoogleMap`}
          />
        </Helmet>
        <span className="spinner-border" role="status" aria-hidden="true" />
      </>
    );
  }

  return (
    <Geosuggest
      inputClassName={`form-control ${error ? 'input-error' : ''}`}
      style={{
        input: {
          ...(error
            ? {
                backgroundColor: '#FFC107',
                border: '0px',
              }
            : {}),
        },
      }}
      placeholder="Address"
      initialValue={_initialValue}
      onSuggestSelect={handleSelectSuggest}
    />
  );
};

export default InputGeoSuggest;
