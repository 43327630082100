import React from 'react';
import { PartConfiguration, PostalAddress } from '@kerfed/client';
import { Shop } from '@kerfed/client';
import { TOrder } from '@kerfed/types';

import EditablePartList from './EditablePartList';
import FooterPrice from './FooterPrice';
import CircularLoading from '../CircularLoading';
import PlaceOrderButton from './PlaceOrderButton';
import StripeCheckout from './StripeCheckout';
import EmailQuoteButton from './EmailQuoteButton';

interface EditablePartListProps {
  order?: TOrder;
  shop?: Shop;
  clientSecret?: string;
  errorPlaceOrder?: string;
  disabledPlaceOrder?: boolean;
  loading?: boolean;
  resetToInitial?: boolean;
  onChangeMethod: (partId: string, newSelectedMethodId: string) => void;
  onChangePartOrderData: (
    lineId: string,
    partId: string,
    newConfig: PartConfiguration,
  ) => void;
  onChangeShipping?: (address: Partial<PostalAddress>) => void;
  onPlaceOrder?: () => void;
  onCancelPlaceOrder?: () => void;
}

const EditablePart = ({
  order,
  shop,
  clientSecret,
  errorPlaceOrder,
  disabledPlaceOrder,
  loading,
  resetToInitial,
  onChangeMethod,
  onChangePartOrderData,
  onChangeShipping,
  onPlaceOrder,
  onCancelPlaceOrder,
}: EditablePartListProps) => {
  const handleChangeOrderData = (
    partId: string,
    newConfig: PartConfiguration,
  ) => {
    if (!order?.lines?.length) return;

    const line = order.lines.find((line) => line?.config?.part_id === partId);
    if (line?.line_id) onChangePartOrderData(line.line_id, partId, newConfig);
  };

  if (!order?.order_id) return null;

  if (!order?.parts?.length) {
    if (!(order?.state as any)?.is_processing) return null;

    return (
      <div>
        <CircularLoading label="Loading" />
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md">
          {!!(order?.state as any)?.is_processing && (
            <div className="position-relative">
              <div className="position-absolute d-flex justify-content-center w-100 z-3">
                <CircularLoading label="Analyzing" />
              </div>
            </div>
          )}

          <div
            style={{
              filter: !!(order?.state as any)?.is_processing
                ? 'blur(0.6rem)'
                : undefined,
            }}
          >
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Part List</th>
                    <th className="price-column">Line Price</th>
                  </tr>
                </thead>

                <EditablePartList
                  order={order}
                  shop={shop}
                  onChangePartOrderData={handleChangeOrderData}
                  onChangeMethod={onChangeMethod}
                />

                {!!order?.parts?.length && (
                  <FooterPrice
                    order={order}
                    resetToInitial={resetToInitial}
                    onChange={onChangeShipping}
                  />
                )}
              </table>
            </div>

            <div className="row g-0">
              <div className="col-md-auto">
                <EmailQuoteButton
                  orderId={order?.order_id}
                  hasPrice={!!order?.total?.total?.amount}
                  disabled={!(order?.total as any)?.is_valid}
                  title={(order?.total as any)?.note}
                />
              </div>

              <div className="col-md-auto">
                <PlaceOrderButton
                  error={errorPlaceOrder}
                  disabled={disabledPlaceOrder || loading}
                  loading={!!loading}
                  title={(order?.total as any)?.note}
                  onClick={onPlaceOrder}
                />
              </div>
            </div>
          </div>

          <StripeCheckout
            clientSecret={clientSecret}
            onCancel={onCancelPlaceOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default EditablePart;
