import React, { useMemo, useState } from 'react';

import EmailForm from './EmailForm';

import { validateEmail } from '@kerfed/common/utils';
import { useAPIOrderEmail } from 'app/src/api/order';

interface EmailQuoteButtonProps {
  orderId?: string;
  hasPrice?: boolean;
  disabled?: boolean;
  title?: string;
}

const EmailQuoteButton = ({
  orderId,
  hasPrice = false,
  disabled = false,
  title,
}: EmailQuoteButtonProps) => {
  const [openEmailForm, setOpenEmailForm] = useState(false);
  const [emails, setEmails] = useState<string[]>(['']);
  const [note, setNote] = useState('');
  const [isFormError, setIsFormError] = useState(false);
  const [success, setSuccess] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  const { onOrderEmail, loading } = useAPIOrderEmail({
    onCompleted: () => {
      setSuccess(true);
      setErrorMessage('');
      setEmails(['']);
      setNote('');
    },
    onError: (e) => {
      setSuccess(undefined);
      setErrorMessage(e.message);
    },
  });

  const error = useMemo(() => {
    return isFormError || !!errorMessage;
  }, [isFormError, errorMessage]);

  const handleOpenEmailForm = () => {
    setOpenEmailForm((prev) => !prev);
  };

  const handleCloseEmailForm = () => {
    setOpenEmailForm(false);
    setSuccess(undefined);
    setErrorMessage('');
    setIsFormError(false);
    setEmails(['']);
    setNote('');
  };

  const handleChangeNote = (newNote: string) => {
    setNote(newNote);
  };

  const handleChangeEmail = (newEmail: string, position: number) => {
    setEmails((prev) => {
      const updatedEmails = prev
        .map((email, index) => {
          if (index === position) {
            return newEmail;
          }
          return email;
        })
        .filter((email) => !!email);

      updatedEmails.push('');
      return updatedEmails;
    });
  };

  const handleSubmit = () => {
    const inputedEmails = emails.filter((email) => !!email);
    const hasErrorEmail = inputedEmails.some((email) => !validateEmail(email));
    if (!inputedEmails.length || hasErrorEmail || !orderId || !hasPrice) {
      setIsFormError(true);
      return;
    }

    setErrorMessage('');
    setIsFormError(false);

    onOrderEmail({
      variables: {
        options: {
          pathParams: { orderId: orderId },
        },
        params: {
          email: inputedEmails,
          note: note || '',
        },
      },
    });
  };

  return (
    <div>
      <button
        className={`btn btn-lg ${openEmailForm ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={handleOpenEmailForm}
      >
        Email This Quote
      </button>

      {openEmailForm && (
        <div className="order-email-expanded">
          <p>
            Email a copy of this quote to yourself or others in your
            organization. A PDF of this quote will be sent, as well as a signed
            link that can be paid with a credit card to place the order. Note
            that the quantities, finishes, materials, etc, in the mailed quoted
            will be the <strong>same as configured on this page</strong>. If you
            haven't configured the parts in this quote, we recommend that you
            configure parts before emailing the quote.
          </p>

          {success ? (
            <div className="alert alert-success rounded-0" role="alert">
              <h2 className="alert-heading">Request Recieved!</h2>
              <p>Your quote will be sent to the following email addresses:</p>
              <ul>
                {emails
                  .filter((email) => !!email) // Ignore empty email fields.
                  .map((email, idx) => (
                    <li key={idx}>{email}</li>
                  ))}
              </ul>
              {note ? (
                <p>
                  <i>Notes: </i>
                  {note}
                </p>
              ) : null}
              <hr />
            </div>
          ) : (
            <EmailForm
              emails={emails}
              note={note}
              error={error}
              message={errorMessage}
              loading={loading}
              onChangeNote={handleChangeNote}
              onChangeEmail={handleChangeEmail}
            />
          )}

          {!success && (
            <button
              type="button"
              disabled={disabled || loading}
              title={title}
              className="btn btn-lg btn-outline-primary"
              style={{
                pointerEvents: 'auto',
                cursor: disabled ? 'default' : 'pointer',
              }}
              onClick={handleSubmit}
            >
              Submit Email Quote{' '}
              {loading && (
                <span
                  className="spinner-border spinner-button"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          )}

          <button
            type="button"
            disabled={loading}
            className="btn btn-lg btn-outline-secondary"
            onClick={handleCloseEmailForm}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailQuoteButton;
