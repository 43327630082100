import React, { Component } from 'react';
import { EmbedContext } from '../contexts';

import { stringHash } from '../../../common/src/utils';

import 'bootstrap/js/dist/modal';

/**
 * Renders a placeholder image in the desired slot until the URL is set *and*
 * the image has actually been loaded.
 *
 * This prevents most layout shifting as the image loads.
 */
class LazyImage extends Component<{ url?: string | null }> {
  render() {
    const { url } = this.props;
    return url ? <img src={url} className="img-fluid" /> : null;
  }
}

export default ({ url }: { url?: string }) => {
  if (!url) {
    return null;
  }

  // set the ID keyed by the URL
  const modalId = `modal-${stringHash(url)}`;

  // are we embedded or not
  // TODO : fix modal position or use popover
  // const { isEmbedded } = useContext(EmbedContext);

  return (
    <EmbedContext.Consumer>
      {({ isEmbedded }) => (
        <div className="Clickable">
          <div data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
            <LazyImage url={url} />
          </div>

          <div
            className={`modal${isEmbedded ? ' modal-embedded' : ''} fade`}
            id={modalId}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className={`modal-dialog modal-xl${
                isEmbedded ? ' modal-dialog-embedded' : ''
              }`}
              role="document"
              data-iframe-height="true"
            >
              <div className="modal-content rounded-0">
                <div className="modal-body align-center">
                  <LazyImage url={url} />
                </div>
                <div className="modal-footer text-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <a href={url} target="_blank" className="btn btn-primary">
                    View Full Screen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </EmbedContext.Consumer>
  );
};
